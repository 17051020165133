.glow-strong {
  text-shadow:
          0 0 2px #fff,
          0 0 4px #fff,
          0 0 6px #fff,
          0 0 20px #0fa,
          0 0 48px #0fa,
          0 0 80px #0fa !important;
}

.glow-light {
  text-shadow:
          0 0 2px #fff,
          0 0 6px #fff,
          0 0 15px #0fa !important;
}